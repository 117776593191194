
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { translate } from "@/core/helpers/translate";
import {
  showErrorPopup,
  showSuccessPopup
} from "@/core/helpers/common-helper";
import ApiService from "@/core/services/ApiService";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface Account {
  accountable_id: string;
  type: string;
  name: string;
  bank_code: string;
  account_number: string;
  account_holder: string;
  balance: number;
  auto_fetch: boolean;
  bcn_ref: Array<string>;
}

export default defineComponent({
  setup() {
    // init
    const { t } = useI18n();
    const route = useRoute();
    const submitButton = ref<null | HTMLButtonElement>(null);

    // breadcrumb
    setNewPageBreadcrumbs(t("management.account.create"), [
      { title: t("management.account.create") }
    ]);

    const create = reactive<Account>({
      accountable_id: '',
      type: '',
      name: '',
      bank_code: '',
      account_number: '',
      account_holder: '',
      balance: 0,
      auto_fetch: false,
      bcn_ref: []
    });

    const resetForm = () => {
      create.accountable_id = '';
      create.type = '';
      create.name = '';
      create.bank_code = '';
      create.account_number = '';
      create.account_holder = '';
      create.balance = 0;
      create.auto_fetch = false;
      create.bcn_ref = []
    }

    const submitForm = () => {
      if (create.name == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Name cannot empty',
          message: 'Name cannot empty',
        })

        return;
      }

      if (create.bank_code == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Bank Code cannot empty',
          message: 'Bank Code cannot empty',
        })

        return;
      }

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        accountable_id: create.accountable_id,
        type: create.type,
        name: create.name,
        bank_code: create.bank_code,
        account_number: create.account_number,
        account_holder: create.account_holder,
        balance: create.balance,
        auto_fetch: create.auto_fetch,
        bcn_ref: create.bcn_ref,
      };

      ApiService.setHeader();
      ApiService.post(
        `app/management/account`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Create Status',
            text: 'Success'
          });
        })
        .catch(({ response }) => {
          showErrorPopup({
            title: response.data.rc,
            text: response.data.message,
            message: response.data.message,
          })
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };


    return {
      create,
      translate,
      submitForm,
      submitButton,
      resetForm
    };
  },
  methods: {}
});
